import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/homepage'
  },
  {
    path: '/homepage',
    name: 'Homepage',
    component: () => import('./../components/homepage.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('./../components/about.vue')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('./../components/product.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('./../components/news.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('./../components/contact.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) { // 解决vue页面之间跳转，页面不是在顶部的问题
    return { x: 0, y: 0 }
  }
})

export default router
