// Connect to the same as the browser URL (only in the browser)
// const restClient = rest()
// import config from '@/config'

const feathers = require('@feathersjs/feathers')
const rest = require('@feathersjs/rest-client')
const auth = require('@feathersjs/authentication-client')
const fapp = feathers()
// const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro
// let baseUrl =  config.baseUrl.pro
const baseUrl = 'http://localhost:3031'
console.log('baseUrl in app=', baseUrl)
// Connect to a different URL
const restClient = rest(baseUrl)

// Configure an AJAX library (see below) with that client
fapp.configure(restClient.fetch(window.fetch))
fapp.configure(auth({
  storageKey: 'feathers-jwt'
}))
//  这里要注册message， service是注册函数
fapp.service('messages')
// // Connect to the `http://feathers-api.com/messages` service
// const messages = fapp.service('messages')
export default fapp
